import React from "react";

const Footer = ({ classicHeader, darkTheme, handleNavClick }) => {
  return (
    <footer
      id="footer"
      className={"section " + (darkTheme ? "footer-dark bg-dark-1" : "")}
    >
      <div
        className={
          "container d-flex justify-content-md-between flex-column flex-md-row" +
          (classicHeader ? "" : "px-lg-5")
        }
      >
        <div className="d-flex gap-4">
          <div className="text-primary text-4 me-2">
            <i className="fas fa-envelope" style={{ fontSize: "25px" }} />
          </div>
          <div className="d-flex flex-column">
            <h6 className="text-light">Email me</h6>
            <a href="mailto:johnfrench.s68@gmail.com">
              johnfrench.s68@gmail.com
            </a>
          </div>
        </div>
        <div className="row mt-2 mt-md-0 justify-content-md-end">
          <div className="text-end">
            <p className="mb-3 mb-lg-0">
              Copyright © 2025{" "}
              <a
                href="#home"
                className="fw-500"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavClick("home");
                }}
              >
                John
              </a>
              . All Rights Reserved.
            </p>
          </div>
          {/* <div className="col-lg-6">
            <ul className="nav nav-separator justify-content-center justify-content-lg-end">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="modal"
                  data-bs-target="#terms-policy"
                  href="#terms-policy"
                >
                  Terms &amp; Policy
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="modal"
                  data-bs-target="#disclaimer"
                  href="#disclaimer"
                >
                  Disclaimer
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
